<template>
  <div class="enroll">
    <!-- <div class="guding">中国产学研合作促进会大赛申报系统</div> -->
    <div class="enroll_main">
      <el-form ref="form" :model="form" style="height: 100%" :rules="rules">
        <el-form-item style="width: 100%" prop="com_name">
          <el-input
            v-model="form.com_name"
            placeholder="请填写申报单位名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item style="width: 100%">
          <el-upload
            action="#"
            :limit="num"
            list-type="picture-card"
            ref="upload"
            :auto-upload="false"
            :on-change="handleDownload"
          >
            <i class="el-icon-upload">请上传单位执照</i>
          </el-upload>
        </el-form-item> -->
        <el-form-item style="width: 100%" prop="branch">
          <el-input v-model="form.branch" placeholder="部门"></el-input>
        </el-form-item>
        <el-form-item style="width: 100%" prop="user_name">
          <el-input
            v-model="form.user_name"
            placeholder="请填写联系人姓名"
          ></el-input>
        </el-form-item>

        <el-form-item style="width: 100%" prop="email">
          <el-input v-model="form.email" placeholder="邮箱"></el-input>
        </el-form-item>
        <el-form-item style="width: 100%" prop="user_email">
          <el-input v-model="form.user_email" placeholder="邮编"></el-input>
        </el-form-item>
        <el-form-item style="width: 100%" prop="contact_address">
          <el-input v-model="form.contact_address" placeholder="联系地址"></el-input>
        </el-form-item>
        <el-form-item prop="phones">
          <el-input
            maxlength="20"
            type="password"
            v-model="form.password"
            placeholder="请输入密码"
          />
        </el-form-item>

        <el-form-item prop="phones">
          <el-input
            maxlength="20"
            type="password"
            v-model="editParrPassword1"
            placeholder="请再次输入密码"
            @blur="Verification"
          />
        </el-form-item>

        <el-form-item style="width: 100%" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item prop="code">
          <el-col :span="14">
            <el-input
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="6"
              type="text"
              v-model="form.code"
              placeholder="请输入验证码"
              style="width: 100%"
            ></el-input>
          </el-col>
          <el-col :span="7" class="count_down" style="margin-left: 10px">
            <el-button
              style="background-color: rgb(156, 44, 44); color: #fff"
              v-if="disabled == false"
              @click="sendcode"
              class="huoqu"
              >获取验证码</el-button
            >
            <el-button
              disabled="disabled"
              type="info"
              v-if="disabled == true"
              @click="sendcode"
              >{{ btntxt }}</el-button
            >
          </el-col>
        </el-form-item>

        <el-form-item style="width: 100%" prop="random">
          <el-input v-model="form.random" placeholder="请输入邀请码"></el-input>
        </el-form-item>

        <div class="btn" @click="btn">立即注册</div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { sms_send, api_sign, base_upload } from "../axios/api.js";
export function checkPhoneNumber(rule, value, callback) {
  const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
  if (!value) {
    return callback(new Error("请填写手机号码！"));
  } else if (!reg.test(value)) {
    return callback(new Error("请填写正确的手机号码！"));
  } else {
    callback();
  }
}
export default {
  data() {
    return {
      num: 1,
      form: {
        password: "",
        mobile: "",
        code: "",
        random: "",
        branch: "",
        user_name: "",
        email: "",
        com_name: "",
        contact_address:"",
        user_email: ""
      },
      img: "",
      editParrPassword1: "",
      disabled: false,
      time: "",
      btntxt: "",
      rules: {
        code: [
          { required: true, message: "请填写验证码（6位）", trigger: "blur" },
        ],
        user_name: [
          { required: true, message: "请填写联系人姓名", trigger: "blur" },
        ],
        com_name: [
          { required: true, message: "请填写申报单位名称", trigger: "blur" },
        ],
        mobile: [
          { required: true, validator: checkPhoneNumber, trigger: "blur" },
        ],
        random: [{ required: true, message: "请填写邀请码", trigger: "blur" }],
        branch: [{ required: true, message: "请填写部门", trigger: "blur" }],
        contact_address: [{ required: true, message: "请填写联系地址", trigger: "blur" }],
        user_email: [{ required: true, message: "请填写邮编", trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },

  components: {},

  methods: {
    handleRemove(file) {
      console.log(file);
      this.$refs.upload.clearFiles(); //调用element官方的方法
    },
    btn() {
      // this.form.file_url = sessionStorage.getItem("img");
      // if (!this.form.file_url) {
      //   this.$message({
      //     message: "上传单位执照",
      //     type: "error",
      //   });
      //   return;
      // }
      // console.log(this.form);

      api_sign(this.form).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: "发送成功",
            type: "success",
          });
          this.$router.push({ path: "/login" });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    sendcode() {
      const reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      console.log(this.form.mobile);
      if (this.form.mobile == "") {
        this.$message({
          message: "手机号不能为空",
          type: "error",
        });
        return;
      }
      if (!reg.test(this.form.mobile)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      } else {
        console.log(123);
        let params = {
          mobile: this.form.mobile,
        };
        console.log(params);
        this.time = 60;
        this.disabled = true;
        this.timer();
        sms_send(params)
          .then((res) => {
            console.log(res);
            let { msg, code } = res;
            if (res.data.code !== 200) {
              this.$message({
                message: "发送失败",
                type: "error",
              });
              this.time = 60;
              this.disabled = true;
              this.timer();
            } else {
              this.$message({
                message: "发送成功",
                type: "success",
              });
              this.time = 60;
              this.disabled = true;
              this.timer();
            }
          })
          .catch(function (reason) {
            //console.log('catch:', reason);
          });
      }
    },
    Verification() {
      if (this.form.password.length >= 6) {
        if (this.editParrPassword1 != this.form.password) {
          this.$message({
            message: "两次密码不一致请重新输入",
            type: "error",
          });
          this.editParrPassword1 = "";
        }
      } else {
        this.form.password = "";
        this.editParrPassword1 = "";
        this.$message({
          message: "密码最低为6位数",
          type: "error",
        });
      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
    handleDownload(file) {
      console.log(123);
      this.getBase64(file.raw).then((res) => {
        console.log(res);
      });
    },

    getBase64(file) {
      let _that = this; //把图片转成base64编码
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
          let params = {
            img: imgResult,
          };
          console.log(params);
          base_upload(params).then((res) => {
            console.log(res);

            if (res.data.code == 200) {
              _that.$message({
                message: "图片上传成功",
                type: "success",
              });
              this.img = res.data.data;
              sessionStorage.setItem("img", this.img);
            } else {
              _that.$message({
                message: res.data.msg,
              });
            }
          });
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
  },
};
</script>

<style scoped>
.enroll {
  width: 100%;
  height: 100%;
  background: url("../assets/static/img/background.jpg") no-repeat 100%;
  background-size: cover;
  overflow: hidden;
}
.enroll_main {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 500px;
  /* height: 750px; */
  box-sizing: border-box;
  padding: 35px 30px;
  /* margin-top: 20px; */
  right: 15%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.enroll_main > div {
  width: 100%;
  height: 9.5%;
  background-color: #fff;
}
.btn {
  width: 100%;
  height: 50px;
  background-color: #890510;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}
.guding {
  position: absolute;
  right: 150px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 40px;
  color: #fff;
}
.huoqu {
  font-size: 14px;
}
</style>